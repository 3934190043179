import React from 'react';
import Peer from 'peerjs';
import ReactDialpad from 'react-dialpad';
import axios from 'axios';
import './App.css';

class App extends React.Component {
    constructor() {
        super();
        this.state = {
            number: '',
            error: null,
            recordedChunks: [],
            isRecording: false,
            dialedNumber: '',
        };
        this.peer = null;
        this.call = null;
        this.mediaRecorder = null;

        this.audioBeep = new Audio('/phone-beep.mp3');
        this.audioMessage = new Audio('/answering-machine.mp3');

        this.getUserMedia = this.getUserMedia.bind(this);
        this.registerCallEvents = this.registerCallEvents.bind(this);
        this.registerPeerEvents = this.registerPeerEvents.bind(this);
        this.onActionInvoked = this.onActionInvoked.bind(this);
        this.onStateChanged = this.onStateChanged.bind(this);
        this.onCallStarted = this.onCallStarted.bind(this);
        this.onCallEnded = this.onCallEnded.bind(this);
        this.onCallReplied = this.onCallReplied.bind(this);
        this.onCallRejected = this.onCallRejected.bind(this);
        this.startRecording = this.startRecording.bind(this);
        this.stopRecording = this.stopRecording.bind(this);
        this.uploadRecording = this.uploadRecording.bind(this);
        this.playBeep = this.playBeep.bind(this);
    }

    componentDidMount() {
        const number = Date.now().toString().slice(-5);
        this.setState({ number });
        this.peer = new Peer(number, { secure: true, port: 443, debug: 3 });
        this.registerPeerEvents(this.peer);
    }

    componentWillUnmount() {
        if (this.call) {
            this.call.close();
        }
        if (this.peer) {
            this.peer.destroy();
        }
        this.stopRecording();
    }

    getUserMedia() {
        return navigator.mediaDevices.getUserMedia({ video: false, audio: true })
            .then(stream => {
                console.log('Got stream:', stream);
                console.log('Audio tracks:', stream.getAudioTracks());
                return stream;
            })
            .catch(err => {
                console.error('Failed to get local stream', err);
                this.setState({ error: 'Failed to get local stream' });
            });
    }
    
    
    registerCallEvents(call) {
        call.on('stream', (remoteStream) => {
            this.dialpad.setOnCall();
        });
        call.on('close', () => {
            this.dialpad.endCall();
        });
    }

    registerPeerEvents(peer) {
        peer.on('call', (call) => {
            this.call = call;
            this.dialpad.setIncomingCall({
                name: call.peer,
                number: call.peer,
            });
        });
    }

    onActionInvoked(action, data) {
        switch (action) {
            case 'CALL_STARTED':
                this.setState({ dialedNumber: data.number });
                this.onCallStarted(data.number);
                break;
            case 'CALL_ENDED':
                this.onCallEnded();
                break;
            case 'CALL_REPLIED':
                this.onCallReplied();
                break;
            case 'CALL_REJECTED':
                this.onCallRejected();
                break;
            default:
                break;
        }
    }

    onStateChanged(state) {
        console.log('State changed:', state);
    }

    onCallStarted(number) {
        console.log('Calling...', number);
        this.getUserMedia()
            .then((stream) => {
                this.call = this.peer.call(number, stream);
                this.dialpad.setRinging();
                this.registerCallEvents(this.call);
                this.playBeep();
            })
            .catch((err) => {
                console.error('Failed to get local stream', err);
                this.setState({ error: 'Failed to get local stream' });
            });
    }

    onCallEnded() {
        if (this.call && this.call.open) {
            this.call.close();
        } else {
            this.dialpad.endCall();
        }
        this.stopRecording();
    }

    onCallReplied() {
        this.getUserMedia()
            .then((stream) => {
                this.call.answer(stream);
                this.registerCallEvents(this.call);
                this.playBeep();
            })
            .catch((err) => {
                console.error('Failed to get local stream', err);
                this.setState({ error: 'Failed to get local stream' });
            });
    }

    onCallRejected() {
        if (this.call && this.call.open) {
            this.call.close();
        } else {
            this.dialpad.endCall();
        }
        this.stopRecording();
    }

    playBeep() {
        let beepCount = 0;
        const beepLoop = () => {
            if (beepCount < 5) {
                this.audioBeep.play().catch(err => console.error('Error playing beep:', err));
                beepCount++;
                this.audioBeep.onended = () => {
                    setTimeout(beepLoop, 1000);
                };
            } else {
                this.audioMessage.play().catch(err => console.error('Error playing message:', err));
                this.audioMessage.onended = () => {
                    this.getUserMedia().then((stream) => {
                        this.startRecording(stream);
                    });
                };
            }
        };
        beepLoop();
    }

    startRecording(stream) {
            
        this.setState({ isRecording: true });
        this.recordedChunks = [];

        this.mediaRecorder = new MediaRecorder(stream);
        this.mediaRecorder.ondataavailable = (event) => {
            if (event.data.size > 0) {
                this.recordedChunks.push(event.data);
                console.log('Data available:', event.data);
            }
        };

        this.mediaRecorder.onstop = async () => {
            console.log('Recording stopped');
            await new Promise(resolve => setTimeout(resolve, 100)); // Short delay
            this.uploadRecording();
        };

        try {
            this.mediaRecorder.start();
            console.log('Recording started');
        } catch (error) {
            console.error('Failed to start recording:', error);
        }
    }

    stopRecording() {
        if (this.mediaRecorder && this.mediaRecorder.state !== 'inactive') {
            this.mediaRecorder.stop();
            this.setState({ isRecording: false });
        }
    }

    async uploadRecording() {
        console.log('Recorded chunks:', this.recordedChunks);

        if (this.recordedChunks.length === 0) {
            console.error('No audio recorded!');
            return; // Exit if no audio
        }

        const blob = new Blob(this.recordedChunks, { type: 'audio/wav' }); // Adjust type if necessary
        const formData = new FormData();
        formData.append('file', blob, 'recording.wav');
        formData.append('from', this.state.dialedNumber);

        try {
            const response = await axios.post('/Home/UploadVoice', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            console.log('Upload successful:', response.data);
        } catch (error) {
            console.error('Error uploading recording:', error);
            this.setState({ error: 'Error uploading recording' });
        }

        this.recordedChunks = []; // Reset recorded chunks after upload
    }

    render() {
        return (
            <div className="app">
                {this.state.error && <div style={{ color: 'red' }}>{this.state.error}</div>}
                <ReactDialpad 
                    style={{backgroundImage: 'url(/bg.jpg)', backgroundSize: 'cover', backgroundPosition: 'center'}}
                    ref={(dialpad) => (this.dialpad = dialpad)}
                    onActionInvoked={this.onActionInvoked}
                    onStateChanged={this.onStateChanged}
                />
                {this.state.isRecording && <div>Recording...</div>}
            </div>
        );
    }
}

export default App;
